import React, { useEffect } from "react"
import { navigate } from "gatsby-link"
import Box from '@mui/material/Box'
import { grey } from '@mui/material/colors'


import { Typography } from "@mui/material"



const Cancel = () => {

 

  useEffect(()=>{

    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('track', 'purchaseCancellation');
      }
    }


    setTimeout(() => {
         navigate('/')
    }, 3000);

  },[])

  return (<Box width="100%" height="100%" marginTop="40vh" display="flex" flexDirection="row" justifyContent="center" justifyItems="center" alignContent="center" alignItems="center">
  <Box borderRadius="20px" width="100%" maxWidth="450px" bgcolor={grey[200]} padding="30px">
      <Typography>
        Vous avez annulé votre inscription sur un événement Pekoia. Si vous voulez y assister, veuillez vous re-inscrire.

      </Typography>
      
      </Box>

  </Box>)
}





export default Cancel